export const InitialValuesProfileINGAccount = {
  profilePicture: "",
  gender: "",
  lastname: "",
  firstname: "",
  email: "",
  phone: "",
  city: "",
  postcode: "",
  street: "",
};