import React from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import exportFileService from "../../../services/ExportFileService";

export const AssetsPayments = [
	{
		Header: "Numéro avoir",
		accessor: "reference",
	},
	{
		Header: "Nom client",
		accessor: "client.niceNames",
		Cell: cellInfo => {
			return (
				<Link to={`/client/${cellInfo.row.original.client.id}`}>{cellInfo.row.original.client.niceNames}</Link>
			)
		}
	},
	{
		Header: "Numéro client",
		accessor: "client.reference",
	},
	{
		Header: "Montant HT",
		accessor: "amount",
		Cell: cellInfo => {
			return (
				<div>{cellInfo.row.original.amount.toFixed(2)} €</div>
			)
		}
	},
	{
		Header: "Montant TTC",
		accessor: "amountWithTax",
		Cell: cellInfo => {
			return (
				<div>{cellInfo.row.original.amountWithTax.toFixed(2)} €</div>
			)
		}
	},
	{

		Header: "Type",
		accessor: "",
		filter: ({ value }) => !!value,
		Cell: cellInfo => {
			if (cellInfo.row.original.refund) {
				return (
					<div className={"green-info"}>{cellInfo.row.original.refund.niceMethod}</div>
				)
			} else {
				return (
					<div>N/A</div>
				)
			}

		}
	},
	{
		Header: "Date",
		accessor: "createdAt",
		Cell: cellInfo => {
			let date = new Date(cellInfo.row.original.createdAt)
			return (
				date.toLocaleDateString()
			)
		}
	},
	{
		Header: "Cause",
		accessor: "reason",
	},
	{
		Header: "Statut",
		accessor: "status",
		Cell: cellInfo => {
			let status = cellInfo.row.original.status
			if (status === "paid") {
				return (
					<div className={"green-info"}>Payé</div>
				)
			} else if (status === "open") {
				return (
					<div className={"orange-info"}>Ouvert</div>
				)
			} else if (status === "signed") {
				return (
					<div className={"green-info"}>Signé</div>
				)
			} else if (status === "processing") {
				return (
					<div className={"orange-info"}>En cours</div>
				)
			} else if (status === "refund") {
				return (
					<div className={"red-info"}>Remboursé</div>
				)
			} else if (status === "canceled") {
				return (
					<div className={"red-info"}>Annulé</div>
				)
			}
		}
	},
	{
		Header: "Actions",
		accessor: "",
		Cell: cellInfo => {

			const downloadFile = () => {
				const date = moment().format("L").split("/").join("-")
				exportFileService.exportFile(`/credits/${cellInfo.row.original.id}`, `export-avoir-${date}`, {}, "pdf")
			}

			return (
				<>
					<div className={"actions-controller"}>
						<img src={"/images/pictos/download.svg"} onClick={() => downloadFile()} title={"Télécharger l'avoir"} />
					</div>
				</>
			)
		}
	},
]
