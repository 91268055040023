import React, {useState} from 'react';
import {Form, Formik} from "formik";
import {SchemaDevisForm} from "../../../../../../../schemas/commercial/SchemaDevisForm";
import {initialValuesDevis} from "../../../../../../../config/InitialValues/InitialValuesDevis";
import FormDevisInformationClient from "./FormDevisInformationClient";
import FormDevisMeetState from "./FormDevisMeetState";
import FormDevisProjectInformations from "./FormDevisProjectInformations";
import FormPriceCurrentDevis from "./FormPriceCurrentDevis";
import FormDevisButtons from "./FormDevisButtons";
import FormDevisArch from "./FormDevisArch";
import FormPartenershipRequest from "./FormPartenershipRequest";
import FormOptionalContract from "./FormOptionalContract";
import {toast} from "react-toastify";
import RequestService from '../../../../../../../services/RequestService';

const FormDevis = ({setShowPopUp, update, setUpdate, project, requestArchForm, isLoading, requestProject}) => {
	const [currentQuote, setCurrentQuote] = useState(0);

	const onSubmit = async (values, action) => {
		let valuesToPush = JSON.parse(JSON.stringify(values));

		if (valuesToPush.client.address.postcode === "") {
			delete valuesToPush.client.address.postcode
		}
		if (valuesToPush.address.postcode === "") {
			delete valuesToPush.client.address.postcode
		}

		if (valuesToPush.client.phone === "") {
			delete valuesToPush.client.phone
		}

		if (valuesToPush.visit === "Visite a prendre avec les ingénieurs") {
			valuesToPush.visit = null
		}

		if (valuesToPush.archIri === "null") {
			valuesToPush.archIri = null
		}

		valuesToPush.quotes.map((itemQuote) => {
			itemQuote.orders.map((itemOrder) => {
				if (itemOrder.idService) {
					itemOrder.serviceIri = `/api/services/${itemOrder.idService}`
				}
				itemOrder.price = parseFloat(itemOrder.price);
				itemOrder.spent = parseFloat(itemOrder.spent);
				itemOrder.margin = parseFloat(itemOrder.margin);
				for (const key in itemOrder) {
					if (Object.prototype.hasOwnProperty.call(itemOrder, key)) {
						/* Vérifier les keys autorisées dans le back */
						if (!["price", "id", "quantity", "serviceIri", "title", "description", "discount", "margin", "spent", "typeOrder", "phase"].includes(key)){
							delete itemOrder[key];
						}
					}
				}
			})
		})

		toast.info("Enregistrement du devis en cours...")
		let clientToSend = {}
		if(valuesToPush.client.gender === "S") {
			clientToSend = {
				email: valuesToPush.client.email,
				gender: valuesToPush.client.gender,
				firstname: valuesToPush.client.firstname,
				lastname: valuesToPush.client.lastname,
				phone: valuesToPush.client.phone,
				agencyName: values.client.agencyName,
				address: {
					street: valuesToPush.client.address.street,
					postcode: valuesToPush.client.address.postcode,
					city: valuesToPush.client.address.city,
					detail: valuesToPush.client.address.detail
				}
			}
		} else {
			clientToSend = {
				email: valuesToPush.client.email,
				gender: valuesToPush.client.gender,
				firstname: valuesToPush.client.firstname,
				lastname: valuesToPush.client.lastname,
				phone: valuesToPush.client.phone,
				address: {
					street: valuesToPush.client.address.street,
					postcode: valuesToPush.client.address.postcode,
					city: valuesToPush.client.address.city,
					detail: valuesToPush.client.address.detail
				}
			}
		}
		
		try {
			await Promise.all([
				RequestService.patchClientInformations(project.client.id, clientToSend),
				...valuesToPush.quotes.map(quote => {

					const cleanQuote = removeEmptyValues({
						projectIri: `/api/projects/${project.id}`,
						orders: quote.orders
					});

					if (quote.id) {
						return RequestService.patchQuotesInformations(quote.id, cleanQuote);
					} else {
						return RequestService.postQuotesInformations(cleanQuote);
					}
				}),
				...valuesToPush.partnerQuotes.map(partner => {
					if (partner.id) {
						return RequestService.patchPartnerInformations(partner.id, {
							total: partner.total,
							status: partner.status,
							title: partner.title,
							profit: partner.profit,
							signedAt: partner.signedAt,
							projectIri: `/api/projects/${project.id}`,
							partnerIri: partner.partnerIri
						});
					} else {
						return RequestService.postPartnerInformations({
							total: partner.total,
							status: partner.status,
							title: partner.title,
							profit: partner.profit,
							signedAt: partner.signedAt,
							partnerIri: partner.partnerIri,
							projectIri: `/api/projects/${project.id}`
						});
					}
				}),
			]);

			await RequestService.patchProjectInformations(project.id, {
				description: values.description,
				phase: values.phase,
				visitType: values.visit,
				optionalContract: values.optionalContract,
				sendOnSubmit: values.sendOnSubmit,
				clientIri: `/api/users/${project.client.id}`,
				archIri: valuesToPush.archIri,
				address: {
					street: valuesToPush.address.street,
					city: valuesToPush.address.city,
					postcode: valuesToPush.address.postcode,
					detail: valuesToPush.address.detail ? valuesToPush.address.detail : ""
				}
			})
	
			// Si toutes les requêtes réussissent, recharge la page et affiche un toast de succès
			window.location.reload();
			toast.success("Les informations ont été enregistrées avec succès.");
		} catch (error) {
			// Si l'une des requêtes échoue, affiche un message d'erreur
			toast.error("Une erreur est survenue lors de l'enregistrement des informations.");
		}
	}


	function removeEmptyValues(obj) {
		const newObj = {};
		for (const key in obj) {
			if (obj[key] !== "" && obj[key] !== null && obj[key] !== undefined && (!Array.isArray(obj[key]) || obj[key].length > 0)) {
				if (typeof obj[key] === "object") {
					const cleanedSubObject = removeEmptyValues(obj[key]);
					if (Object.keys(cleanedSubObject).length > 0) {
						newObj[key] = cleanedSubObject;
					}
				} else {
					newObj[key] = obj[key];
				}
			}
		}
		return newObj;
	}

	return (
<>
		<Formik enableReinitialize={true} initialValues={initialValuesDevis} validationSchema={SchemaDevisForm}
		        onSubmit={onSubmit}>
			<Form className={"form-devis"}>
				<div className={"form-devis-container"}>
					<FormDevisInformationClient project={project}/>
					<div className={"form-devis-row"}>
						<FormDevisMeetState project={project}/>
						<FormDevisArch project={project} requestArchForm={requestArchForm} setShowPopUp={setShowPopUp}/>
					</div>
					<FormDevisProjectInformations isLoading={isLoading} project={project} setUpdate={setUpdate} update={update}
					                              setCurrentQuote={setCurrentQuote} currentQuote={currentQuote}/>
					<FormPriceCurrentDevis currentQuote={currentQuote} update={update}/>
					<FormPartenershipRequest project={project} setUpdate={setUpdate} update={update}/>
					<FormOptionalContract project={project}/>
				</div>
				<FormDevisButtons project={project} setUpdate={setUpdate} update={update}/>
			</Form>
			
		</Formik>
		</>
	);
};


export default FormDevis;
