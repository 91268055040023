import React, { useEffect, useState } from 'react';
import DirRespService from '../../../services/Ing/DirResp/DirRespService';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';

const PopUpObjectifsTemporels = ({ id, onClick }) => {
	const [taskLoading, setTaskLoading] = useState(true);
	const [comments, setComments] = useState()

	const getComments = async () => {
		setTaskLoading(true);
		let request = await DirRespService.getComments(id);
		if (request.request.status === 200) {
			setComments(request.data['hydra:member'])
			setTaskLoading(false);
		}
	}

	useEffect(() => {
		getComments()
	}, [id])

	return (
		<div className={"pop-up-base-style active"}>
			<div className={"pop-up-user-delete"}>
				<img alt='cross-close' onClick={onClick} className={"cross-close"} src={"/images/pictos/cross-close.svg"} />
				<h4 className='color-primary'>Liste des commentaires</h4>
				{
					taskLoading ? (
						<Skeleton height={200} width={636} />
					) : (
						<div className='container-comments' style={{maxHeight: "400px", overflowY: "auto", display:"flex", flexDirection:"column", gap:"10px"}}>
							{
								comments.map((comment, index) => {
									return (
										<div key={index} className="list-commentaires-item">
											<div className="list-commentaires-item-content">
												<p className="text-primary color-primary paragraph_bold">
													{comment.user.niceNames} : {moment(comment.createdAt).format("DD/MM/YYYY")} à {moment(comment.createdAt).format("HH:mm")}
												</p>
												<p className="text-primary color-primary">
													{comment.content}
												</p>
											</div>
										</div>
									)
								})
							}

						</div>
					)
				}
			</div>
		</div>
	);
}

export default PopUpObjectifsTemporels;
