import React, { createContext, useEffect, useState } from "react";
import { HOME_PATH } from "../../../../../config/UrlConstants";
import CardCircuitDeValidationIng
	from "../../../../../components/Molecules/Cards/CircuitDeValidation/CardCircuitDeValidationIng";
import TitleIconNavLink from "../../../../../components/Atomes/Title/TitleIconNavLink";
import ButtonSubmit from "../../../../../components/Atomes/Buttons/ButtonSubmit";
import exportFileService from '../../../../../services/ExportFileService';
import { toast } from "react-toastify";
import DirRespService from "../../../../../services/Ing/DirResp/DirRespService";

export const StateRequest = createContext()
const ApprovalCircuitView = () => {
	const [stateRequest, setStateRequest] = useState("");
	const [approvalCircuit, setApprovalCircuit] = useState([]);
	const downloadFile = () => {
		exportFileService.exportFile("/engineer/validation-process", 'export-circuitvalidation', {}, "xlsx")
	}

	const DisplayToast = (type) => {
		if (type === "success add") {
			toast.success("La remise a été effectuée avec succès")
		} else if (type === "success delete") {
			toast.success("La remise n'a pas été validée")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	useEffect(() => {
		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getApprovalCircuit()
	}, [stateRequest]);

	const getApprovalCircuit = async () => {
		let request = await DirRespService.getApprovalCircuit()
		if (request.request.status === 200) {
			setApprovalCircuit(request.data["hydra:member"])
		} else {
			toast.error("Une erreur est survenue lors de la récupération du circuit d'approbation")
		}
	}

	return (
		<StateRequest.Provider value={{ stateRequest, setStateRequest }}>
			<div className={"mbe-section"}>
				<div className={"section-circuit-de-validation-title"}>
					<TitleIconNavLink title={"Tableau de bord"} link={HOME_PATH} />
					{
						approvalCircuit.length > 0 &&
						<ButtonSubmit isActive={false} onClick={downloadFile} title={"Exporter"}
							styleButton={"btn-default btn-default-blue btn-download color-primary"} />
					}
				</div>
				{
					approvalCircuit.length === 0 ? (
						<p className="section-circuit-de-validation-empty text-primary color-primary">Pas de validation en
							attente</p>
					) : (
						<div className={"section-circuit-de-validation-view-container"}>
							{approvalCircuit.map((circuit, index) =>
								<CardCircuitDeValidationIng
									id={circuit.id}
									key={index}
									title={circuit.niceStage}
									status={circuit.niceStageStatus}
									niceNames={circuit.client ? circuit.client.niceNames : ""}
									numberFolder={circuit.reference}
									devisId={circuit.id}
									img={circuit.client ? circuit.client.urlAvatar : ""}
									color={circuit.studyControl === "minor" ? "orange" : "red"}
									styleButton={circuit.studyControl === "minor" ? "orange" : "red"}
									niceStudyStatus={circuit.niceStudyControl}
								/>
							)}
						</div>
					)
				}

			</div>
		</StateRequest.Provider>
	)
}

export default ApprovalCircuitView;
