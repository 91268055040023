import React, { useState, useEffect } from 'react';

import TitleIconNavLink from '../../../../components/Atomes/Title/TitleIconNavLink';
import ProfileCustomerAccountForm from './Partials/Account/Form';
import ProfileCustomerPasswordForm from './Partials/Password/Form';
import ProfileCustomerBankForm from './Partials/Bank/Form';

import api from "../../../../services/APIService";
import Skeleton from 'react-loading-skeleton';

const ProfileCustomer = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [accountFormData, setAccountFormData] = useState(null);
  const [bankFormData, setBankFormData] = useState(null);

  const getUserData = async () => {
    const userId = localStorage.getItem("userId");
    if (userId != null) {
      let request = await api.get(`clients/${userId}`);
      if (request) {
        setAccountFormData({
          agencyName: request.agencyName != "" ? request.agencyName : "",
          gender: request.gender,
          firstname: request.firstname,
          lastname: request.lastname,
          email: request.email,
          phone: request.phone,
          address: {
            street: request.address.street,
            postalCode: request.address.postcode,
            city: request.address.city,
            addressComplement: request.address.detail ? request.address.detail : "",
          },
        });
        setBankFormData({
          accountHolder: request.accountHolder,
          iban: request.iban,
          bic: request.bic,
        });
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <section>
      <TitleIconNavLink title={"Tableau de bord"} link={"/"} />
      {
        isLoading ? <Skeleton height={400} /> :
          <>
            <ProfileCustomerAccountForm
              accountFormData={accountFormData}
            />
            <ProfileCustomerBankForm
              bankFormData={bankFormData}
            />
            <ProfileCustomerPasswordForm />
          </>
      }
    </section>
  )
}

export default ProfileCustomer;