import React from "react";

const TimeCalculated = ({data}) => {

	return (
		<div className={"engineer-objectives-section-total"}>
			<div className={"engineer-objectives-section-total-container"}>
				<div className={"engineer-objectives-section-total-container-name"}>
					<p className="text-primary paragraph_bold">Temps total prévisionnel</p>
					<p className="text-primary paragraph_bold">Temps total réel</p>
					<p className="text-primary paragraph_bold">Différence totale</p>
					<p className="text-primary paragraph_bold">Total taux de marge</p>
					<p className="text-primary paragraph_bold">Volume final</p>
					<p className="text-primary paragraph_bold">Taux d’acquisition</p>
					{/* <p className="text-primary paragraph_bold">Taux de production</p> */}
				</div>
				<div className={"engineer-objectives-section-total-container-price"}>
					<p className="text-primary paragraph_bold">{data.tempPrev}</p>
					<p className="text-primary paragraph_bold">{data.tempReel}</p>
					<p className="text-primary paragraph_bold">{data.tempEcartNegative ? "-" : ""} {data.tempEcart}</p>
					<p className="text-primary paragraph_bold">{data.tauxMarge ? data.tauxMarge.toFixed(2) : '0.00'} %</p>
					<p className="text-primary paragraph_bold">{data.volume.toFixed(2)} €</p>
					<p className="text-primary paragraph_bold">{data.tauxAcquisition ? data.tauxAcquisition.toFixed(2) : '0.00'} %</p>
					{/* <p className="text-primary paragraph_bold">{data.tauxProduction ? data.tauxProduction.toFixed(2) : '0.00'} %</p> */}
				</div>
			</div>
		</div>
	)
		;
};
export default TimeCalculated;
