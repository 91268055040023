import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import SchemaValidTasks from './Config/SchemaValidTasks';

import ApiService from '../../../../../services/APIService';
import { useState } from 'react';
import InputTextareaErrorMessage from '../../../../Atomes/Formik/InputTextareaErrorMessage';

const PopupValidTask = ({ showPopup, closeAction, taskValidated, taskValidatedType, taskValidatedId, pro }) => {

  const timeToFloat = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const floatTime = hours + (minutes / 60);
    return parseFloat(floatTime.toFixed(2));
  }

  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");

  const generateOptions = (max) => {
    const options = [];
    for (let i = 0; i <= max; i++) {
      options.push(
        <option key={i} value={i}>
          {i < 10 ? `0${i}` : i}
        </option>
      );
    }
    return options;
  };

  const submitForm = async (values, setSubmitting) => {
    let effectiveDurationFloat = timeToFloat(hours + ":" + minutes);
     let data = {
      effectiveDuration: effectiveDurationFloat,
      categoryType: taskValidatedType,
      commentContent: values.comment,
      completed : true,
    }

    let request = await ApiService.patch(`assignments/${taskValidatedId}`, data);
    if (request.status === 200) {
      closeAction();
      taskValidated();
      toast.success("Temps passé sur la tâche enregistré");
    } else {
      toast.error("Erreur lors de l'enregistrement du temps passé sur la tâche");
    } 
  }

  return (
    <div className={`pop-up-base-style ${showPopup ? 'active' : ""}`}>
      <div className={"pop-up-simple card-task-popup"}>
        <img className={"pop-up-simple-cross"} src={"/images/pictos/cross-close.svg"} alt={"cross"} onClick={closeAction} />
        <p className="text-primary">Combien de temps avez-vous mis pour la réalisation de cette tâche ?</p>
        <Formik
          initialValues={{
            effectiveDuration: '00:00',
            comment: '',
          }}
          validationSchema={SchemaValidTasks}
          onSubmit={(values, { setSubmitting }) => {
            submitForm(values, setSubmitting);
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className={"form-row-element"}>
                <label className='text-primary' htmlFor="effectiveDuration">Temps passé sur la tâche (en heures et minutes)</label>
                {/* Sélecteur pour les heures */}
                <div style={{paddingTop:'5px', display:"flex", alignItems:"center"}}>
                  <select
                    value={hours}
                    style={{minWidth:"70px !important"}}
                    onChange={(e) => setHours(e.target.value)}
                    aria-label="Heures"
                  >
                    <option value="" disabled>Heures</option>
                    {generateOptions(60)}
                  </select>
                  <h3> : </h3> 

                  {/* Sélecteur pour les minutes */}
                  <select
                    value={minutes}
                    style={{minWidth:"70px !important"}}
                    onChange={(e) => setMinutes(e.target.value)}
                    aria-label="Minutes"
                  >
                    <option value="" disabled>Minutes</option>
                    {generateOptions(59)}
                  </select>
                </div>
              </div>
              <div style={{marginBottom: "20px"}}>
              <InputTextareaErrorMessage
                name={"comment"}
                placeholder={"Votre commentaire"}
                className={"commentaire-textarea max-width-480"}
                wordLimit={200}
              />
              </div>

              <div className={"form-row-element"}>
                <button
                  className="btn-default btn-default-primary"
                  type="submit"
                  style={{display: 'flex', marginInline: 'auto'}}
                  disabled={isSubmitting ? true : false}
                >
                  Valider
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default PopupValidTask