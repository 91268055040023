import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import ProjectNiceType from "../../../utils/ProjectNiceType";
import ProjectNiceStage from '../../../utils/ProjectNiceStage';

const ProjectHeader = ({ project, projectStep,
  priValidationAction, priDisproveAction,
  studyValidationStep, studyValidationStepEditAction, studyValidationStepValidAction
}) => {

  const [isDocumentsView, setIsDocumentsView] = useState(false);
  useEffect(() => {
    if (window.location.href.includes('documents')) {
      setIsDocumentsView(true);
    }
  }, []);

  return (
    <>
      <section className='project-header'>
        <header className='project-header__banner'>
          {ProjectNiceType({ type: project.type, value: "bannerImg" })}
        </header>

        {/* Heading */}
        <div className='project-header__content'>
	        <div className={"title-icon-nav-link"}>
          <Link to={"/"} className={"title-icon-nav-link"}>
            <img src={"/images/pictos/arrow-left.svg"} alt="arrow left" />

            <h1>
              {project.address.street} {project.address.postcode} {project.address.city}
            </h1>
          </Link>
	        <img
		        src={"/images/pictos/info-black.svg"}
		        title={project.niceType}
	        />
	        </div>
          {
            projectStep !== "finished" &&
            <span className={'color-white text-primary span-disabled span-disabled-blue'}>
              {ProjectNiceStage({ stage: project.stage, niceStage: project.niceStage, canceledAt: project.canceledAt })}
            </span>
					}

				</div>

				{/* Tabs */}
				<div className='project-header__content'>
					<div className='project-header__toggletabs'>
						<Link to={"/projet/" + project.id}
						      className={`text-primary project-header__toggletabs__tab ${!isDocumentsView ? 'active' : ''}`}>
							Suivi projet
						</Link>
						<Link to={"/projet/" + project.id + "/documents"}
						      className={`text-primary project-header__toggletabs__tab ${isDocumentsView ? 'active' : ''}`}>
							Documents
						</Link>
					</div>

					{
						projectStep !== "finished" && projectStep !== "documents" && projectStep !== "studyValidation" && projectStep !== "priValidation" &&
						<span className={'color-dark-grey text-primary span-disabled'}>
              <img src={"/images/pictos/timeclock-darkgray.svg"} alt='information' title='Ouverture de mur'/>
              Réception du dossier estimée à {ProjectNiceType({type: project.type, value: "time"})} jours ouvrés après la validation du principe structure
            </span>
					}

					{/* {
						projectStep === 'studyValidation' && (
							<div className='project-header__content__btns'>
								{
									studyValidationStep !== 'editing' && (
										<>
											<button className='btn-default btn-purple-secondary' onClick={studyValidationStepEditAction}>Demander
												des modifications
											</button>
											<button className='btn-default btn-purple' onClick={studyValidationStepValidAction}>Valider dossier
												d'étude structure
											</button>
										</>
									)
								}
							</div>
						)
					} */}

					{
						projectStep === 'priValidation' && (
							<>
								<div className='project-header__content__btns'>
									<button
										onClick={priDisproveAction}
										className='btn-default btn-purple-secondary'
									>
										Refuser avec observations
									</button>
									<button
										onClick={priValidationAction}
										className='btn-default btn-purple'
									>
										Accepter le principe de structure
									</button>
								</div>
							</>
						)
					}

				</div>


			</section>
		</>
	);
}

export default ProjectHeader;
