import { NavLink } from "react-router-dom";

import ProjectNiceType from "../../../../utils/ProjectNiceType";
import ProjectNiceStage from "../../../../utils/ProjectNiceStage";

const CardProjectLandscape = ({ id, project }) => {
  const userRole = localStorage.getItem("role");
  
  return (
    <article className={"card-project-landscape"}>
      <div className={"card-project-landscape-img"}>
        {ProjectNiceType({ type: project.type, value: "cardImg" })}
        <div
          className={`${
            (project.stage === "COM_feedback") && !project.canceledAt ? "done" : ""
          }
          ${(project.stage === "COM_feedback") && project.canceledAt ? "archived" : ""}
          card-project-landscape-img-status`}
        >
          <p className={"text-primary color-white"}>
            {ProjectNiceStage({
              stage: project.stage,
              niceStage: project.niceStage,
              canceledAt: project.canceledAt
            })}
          </p>
        </div>
      </div>

      <div className={"card-project-landscape-content"}>
        <NavLink to={`/projet/${id}`} className="text-primary color-black">
          <span className="invisible-link"></span>
        </NavLink>

        <div className="card-project-landscape-content__header">
          <h4>{`${project.address.street}, ${project.address.postcode} ${project.address.city} `}</h4>
          <p className={"text-primary color-black"}>
            {`${project.niceType} - ${project.reference}`}
          </p>
        </div>
        <div className={"card-project-landscape-content__teams"}>
          {project.ingDiag && (
            <div>
              <p className={"text-micro paragraph_bold color-black"}>
                Ingénieur diagnostiqueur :
              </p>
              <p className={"text-primary color-black"}>
                {project.ingDiag.niceGender} {project.ingDiag.niceNames}
              </p>
            </div>
          )}
          {project.arch && (
            <div>
              <p className={"text-micro paragraph_bold color-black"}>
                Architecte :
              </p>
              <p className={"text-primary color-black"}>
                {project.arch.niceGender} {project.arch.niceNames}
              </p>
            </div>
          )}
          {project.ingAff && (
            <div>
              <p className={"text-micro paragraph_bold color-black"}>
                {" "}
                Ingénieur Chargé(e) d'Affaires :
              </p>
              <p className={"text-primary color-black"}>
                {project.ingAff.niceGender} {project.ingAff.niceNames}
              </p>
            </div>
          )}
          {project.com && (
            <div>
              <p className={"text-micro paragraph_bold color-black"}>
                Commercial :
              </p>
              <p className={"text-primary color-black"}>
                {project.com.niceGender} {project.com.niceNames}
              </p>
            </div>
          )}
          {project.ingChecker && (
            <div>
              <p className={"text-micro paragraph_bold color-black"}>
                Ingénieur vérificateur :
              </p>
              <p className={"text-primary color-black"}>
                {project.ingChecker.niceGender} {project.ingChecker.niceNames}
              </p>
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

export default CardProjectLandscape;
