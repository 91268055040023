import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field} from "formik";

const InputLabelTagIngErrorMessage = ({
	                                name,
	                                type,
	                                label,
	                                placeholder,
	                                data,
	                                dataTag,
	                                keyValue,
	                                keyLabel,
	                                keyType,
	                                readonly,
	                                canBeNull,
									onClick,
									onChange,
	                                className
                                }) => {

	const [dataTagCompetences, setDataTagCompetences] = useState(dataTag)
	const [dataCompetences, setDataCompetences] = useState(data)

	const competencesSelect = () => {
		let competencesUpdate = data.filter(dataCompetence => !dataTagCompetences.some(tag => tag.title === dataCompetence.name));
		setDataCompetences(competencesUpdate)
	}

	useEffect(() => {
		competencesSelect()
	}, [dataTagCompetences]);

	useEffect(() => {
		if (!dataTag || !data) return;
		const updatedTags = dataTag.map(tag => {
			const correspondingData = data.find(item => item.name === tag.title);
			return {
				...tag,
				color: correspondingData ? correspondingData.color : "#000000"
			};
		});
		setDataTagCompetences(updatedTags);
	}, [dataTag, data]);
	

	return (
		<div className={"form-row-label-input"}>
			<label htmlFor={name} className={"text-micro paragraph_bold color-dark-grey marginbottom-8"}>{label}</label>
			<div className="form-row-label-input-tag">
				{dataTagCompetences.map((item, index) => {
					return (
						<div key={index} className="form-row-label-input-tag-item">
							<div className="form-row-label-input-tag-item-round" style={{backgroundColor: item[keyType]}}></div>
							<p className="text-primary color-primary form-row-label-input-tag-item-text">{item[keyLabel]}</p>
							<div className="form-row-label-input-tag-item-cross" onClick={() => onClick(item.title)}>
								<img src="/images/pictos/cross-background-red.svg" alt=""/>
							</div>
						</div>
					)
				})}
			</div>
			<Field
				multiple={false}
				className={"select-custom-mini-secondary"}
				as={type}
				name={name}
				placeholder={label}
				onChange={onChange}
			>
				<option selected={true} hidden>{placeholder === undefined ? label : placeholder}</option>
				{dataCompetences.map((item, index) => {
					if (readonly === "readonly") {
						return (
							<option disabled key={index} value={item[keyValue]}>{item.name}</option>
						)
					} else {

						return (
							<option key={index} value={item[keyValue]}>{item.name}</option>
						)
					}
				})}
				{canBeNull ? <option value={null}>N/A</option> : ""}
			</Field>
			<ErrorMessage name={name} component={"p"} className={"color-error text-primary"}/>
		</div>
	);
};

export default InputLabelTagIngErrorMessage;
