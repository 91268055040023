
import React, {useState} from "react";
import {GetAllowedRoutes, GetAllowedSidebarElement} from "../utils/RolesChecker";
import PrivateRoutesConfig from "../config/PrivateRoutesConfig";
import MapAllowedRoutes from "./routes/MapAllowedRoutes";
import SidebarConfig from "../config/SidebarConfig";
import SideBar from "../views/Layouts/SideBar";
import TitleHelloUser from "../components/Atomes/Title/TitleHelloUser";
import {useLocation} from "react-router-dom";
import Chatbot from "../views/Chatbot/Chatbot";
import {useAuth} from "../services/useAuth";
import {dataChatInterneAllowed} from "../utils/UserRolesUtils";
import Chat from "../views/Chat/Chat";
import MenuBurger from "../views/Layouts/MenuBurger";

function PrivateRoutes() {
    let allowedRoutes = [];
    let allowedSidebar = [];
    const { role } = useAuth();
    const dataChatInterne = dataChatInterneAllowed;
    const allowedRoutesChatInterne = dataChatInterne.includes(role[0]);

    allowedRoutes = GetAllowedRoutes(PrivateRoutesConfig);
    allowedSidebar = GetAllowedSidebarElement(SidebarConfig);

    const location = useLocation();
    const isHome = location.pathname === "/";

    const currentRoute = allowedRoutes.find(route => route.path === location.pathname);
    const titleHelloUser = currentRoute?.titleHelloUser;
    const pageTitle = currentRoute?.title;

    const [isSidebarExpand, setIsSidebarExpand] = useState(false);
    const toggleSidebar = () => setIsSidebarExpand(!isSidebarExpand);

    return (
        <>
            {location.pathname === "/chatbot" ? (
                <Chatbot />
            ) : (
                <>
                    <SideBar allowedSidebar={allowedSidebar} toggleSidebar={toggleSidebar} isSidebarExpand={isSidebarExpand} />
                    <MenuBurger allowedSidebar={allowedSidebar} />
                    <div className={`page-container ${isSidebarExpand ? "page-container-collapsed" : ""}`}>
                        <div>
                            {/* Affichage du titre en fonction des conditions */}
                            {isHome && (titleHelloUser || titleHelloUser === undefined) ? (
                                <TitleHelloUser />
                            ) : !isHome && titleHelloUser ? (
                                <>
                                    {/* <h1 className={"color-primary"}><span className={"lighter"}>Page - </span>{pageTitle}</h1> */}
                                    <h1 className={"color-primary"}>{pageTitle}</h1>
                                </>
                            ) : null}

                            <MapAllowedRoutes routes={allowedRoutes} basePath="/" isAddNotFound />
                            {allowedRoutesChatInterne && <Chat />}
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default PrivateRoutes;