import React from 'react';
import {ErrorMessage, Field} from "formik";

const InputTextareaErrorMessage = ({
	                                name,
	                                placeholder,
	                                readonly,
	                                className,
									wordLimit
                                }) => {

		const handleWordLimit = (e) => {
			if (!wordLimit) return;
	
			const words = e.target.value.trim().split(/\s+/);
			if (words.length > wordLimit) {
				e.target.value = words.slice(0, wordLimit).join(" ");
			}
		};

		return (
			<div className={"form-row-label-input"}>
				<Field readOnly={readonly} as="textarea" name={name} className={className}
				       placeholder={placeholder} onInput={handleWordLimit}/>
				<ErrorMessage name={name} component={"p"} className={"color-error text-primary"}/>
			</div>
		)
};

export default InputTextareaErrorMessage;
