import React, { useEffect, useState } from 'react';
import { Form, Formik } from "formik";
import ButtonSubmit from "../../../Atomes/Buttons/ButtonSubmit";
import userService from "../../../../services/UserService";
import { initialValuesIngCollaborator } from "../../../../config/InitialValues/Engineer/initialValuesIngCollaborator";
import { ingCollaboratorSchema } from "../../../../schemas/Engineer/ingCollaboratorSchema";
import Button from "../../../Atomes/Buttons/Button";
import PopUpDeleteProjectCollaborator from "../../PopUp/PopUpDeleteProjectCollaborator";
import DirRespService from "../../../../services/Ing/DirResp/DirRespService";
import api from "../../../../services/APIService";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import InputLabelErrorMessage from '../../../Atomes/Formik/InputLabelErrorMessage';
import InputLabelTagIngErrorMessage from '../../../Atomes/Formik/InputLabelTagIngErrorMessage';

const FormIngCollaborator = ({
	collaborator,
	allProjects,
	allRoles,
	setShowPopUpAssignProject,
	selectedProgressItem,
	updateData
}) => {

	const { addUser } = userService
	const [showPopUpDeleteProject, setShowPopUpDeleteProject] = useState(false);
	const [collaboratorData, setCollaboratorData] = useState(collaborator)
	const [projectData, setProjectData] = useState(collaborator)
	const [generalAbilities, setGeneralAbilities] = useState([]);
	const [visitAbilities, setVisitAbilities] = useState([]);
	const [visitTransportations, setVisitTransportations] = useState([]);
	const [tagsUpdate, setTagsUpdate] = useState([])
	const [loading, setLoading] = useState(true)
	const [selectedProject, setSelectedProject] = useState("")
	const [currentProject, setCurrentProject] = useState([])

	const deleteProjectProgress = async () => {
		const request = await DirRespService.unassignProject(selectedProject)

		if (request.request.status === 204) {
			toast.success("Le projet a bien été supprimé", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 3000,
			})
			setShowPopUpDeleteProject(false)
			updateData()
		} else {
			toast.error("Une erreur est survenue", {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 3000,
			})
		}
	}

	const allCompetences = [
		{ "name": "Autocad", "color": "#58586A" },
		{ "name": "Autocad LT", "color": "#6F6A97" },
		{ "name": "Robot Structural Analysis", "color": "#9D8DF1" },
		{ "name": "Graitec Arche Hybride", "color": "#ABADF5" },
		{ "name": "SCIA Engineer", "color": "#CFC8D9" },
		{ "name": "Béton", "color": "#E1D6CB" },
		{ "name": "Bois", "color": "#F2E3BC" },
		{ "name": "Mixte", "color": "#A55F7C" },
		{ "name": "Charpentes métalliques", "color": "#DB5461" },
		{ "name": "Métal", "color": "#E79C8F" },
		{ "name": "Sika CarboDur", "color": "#519E8A" },
		{ "name": "Débutant", "color": "#A2C1A3" },
		{ "name": "Junior", "color": "#CAD2B0" },
		{ "name": "Confirmé", "color": "#466060" },
		{ "name": "Senior", "color": "#9CA28E" },
		{ "name": "Béton précontraint", "color": "#4F7466" },
		{ "name": "Diagnostic pathologie du bâtiment", "color": "#4357AD" },
		{ "name": "Ingénieur diagnostiqueur", "color": "#48A9A6" }
	];

	const allVisitAbilites = [
		{ "name": "Habilité sondage", "color": "#58586A" },
		{ "name": "Gants (EPI)", "color": "#6F6A97" },
		{ "name": "Chaussures (EPI)", "color": "#9D8DF1" },
		{ "name": "Pantalon (EPI)", "color": "#ABADF5" },
		{ "name": "Polo (EPI)", "color": "#CFC8D9" },
		{ "name": "Veste (EPI)", "color": "#E1D6CB" },
		{ "name": "Casque (EPI)", "color": "#F2E3BC" },
		{ "name": "Casquette coquée (EPI)", "color": "#A55F7C" },
	];

	const allVisitTransportations = [
		{ "name": "Vélo", "color": "#58586A" },
		{ "name": "Vélo cargo", "color": "#6F6A97" },
		{ "name": "Train", "color": "#9D8DF1" },
		{ "name": "Voiture de fonction", "color": "#ABADF5" },
		{ "name": "Voiture de service", "color": "#CFC8D9" },
		{ "name": "Transport en commun", "color": "#E1D6CB" },
		{ "name": "Scooter de fonction", "color": "#F2E3BC" },
	];

	const deleteCompetenceGeneralAbilities = (title) => {
		let competencesTagUpdate = generalAbilities.filter(competenceTag => competenceTag.title !== title)
		setGeneralAbilities(competencesTagUpdate)
	}

	const updateCompetenceGeneralAbilities = (e) => {
		setGeneralAbilities([...generalAbilities, { title: e, color: "red" }])
	}

	const deleteCompetenceVisitAbilities = (title) => {
		let competencesTagUpdate = visitAbilities.filter(competenceTag => competenceTag.title !== title)
		setVisitAbilities(competencesTagUpdate)
	}

	const updateCompetenceVisitAbilities = (e) => {
		setVisitAbilities([...visitAbilities, { title: e, color: "red" }])
	}

	const deleteCompetenceVisitTransportations = (title) => {
		let competencesTagUpdate = visitTransportations.filter(competenceTag => competenceTag.title !== title)
		setVisitTransportations(competencesTagUpdate)
	}

	const updateCompetenceVisitTransportations = (e) => {
		setVisitTransportations([...visitTransportations, { title: e, color: "red" }])
	}

	const onSubmit = async (values, actions) => {
		values.generalAbilities = generalAbilities
		values.visitAbilities = visitAbilities
		values.visitTransportations = visitTransportations
			const updatedData = {
				generalAbilities: values.generalAbilities.map(tag => {
					const { color, ...rest } = tag;
					return rest;
				}),
				visitAbilities: values.visitAbilities.map(tag => {
					const { color, ...rest } = tag;
					return rest;
				}),
				visitTransportations: values.visitTransportations.map(tag => {
					const { color, ...rest } = tag;
					return rest;
				}),
				workingRegion: values.workingRegion,
			};

			const request = await api.patch(`ings/${collaborator.id}`, {
				generalAbilities: updatedData.generalAbilities,
				visitAbilities: updatedData.visitAbilities,
				visitTransportations: updatedData.visitTransportations,
				roles: [collaborator.roles[0]],
				workingRegion: updatedData.workingRegion
			})

			if (request.request.status === 200) {
				updateData()
				actions.resetForm();
				toast.success("Les compétences ont bien été modifiées", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
				})

			} else {
				toast.error("Une erreur est survenue", {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 3000,
				})
			}
	}

	const getUserProject = async () => {
		const request = await DirRespService.getCurrentProject(collaborator.id)

		if (request.request.status === 200) {
			setCurrentProject(request.data["hydra:member"])
			setGeneralAbilities(collaborator.generalAbilities);
			setVisitAbilities(collaborator.visitAbilities);
			setVisitTransportations(collaborator.visitTransportations);
			initialValuesIngCollaborator.workingRegion = collaborator.workingRegion;
			setTagsUpdate(collaborator.tags)
			setLoading(false)
		}
	}

	useEffect(() => {
		setSelectedProject("")
		setLoading(true)
		setGeneralAbilities([])
		setVisitAbilities([])
		setVisitTransportations([])
		getUserProject()
	}, [setProjectData, updateData]);
	
	return (
		<>
			{
				loading ? <Skeleton height={500} /> : (
					<>
						<Formik
							initialValues={initialValuesIngCollaborator}
							validationSchema={ingCollaboratorSchema}
							onSubmit={onSubmit}
						>

							{({ isSubmitting, isValid }) => (
								<Form className={"pop-up-form-container-collaborator"}>
									<div className={"card-collaborator-title"}>
										<img src={`${process.env.REACT_APP_PROD_URL}/${collaborator.urlAvatar}`} />
										<div className={"card-collaborator-title-text"}>
											<h4 className={"color-primary"}>{collaboratorData.niceNames}</h4>
											<p className={"text-primary color-primary"}>{collaboratorData.job}</p>
										</div>
									</div>
									<div className={"card-collaborator-contact"}>
										<div className={"card-collaborator-contact-email"}>
											<img src={"/images/pictos/letter.svg"} alt={"email img"} />
											<a href={`mailto:${collaboratorData.email}`} style={{ textDecoration: "none" }} className={"text-micro paragraph_bold color-primary"}>{collaboratorData.email}</a>
										</div>
										<div className={"card-collaborator-contact-phone"}>
											<img src={"/images/pictos/phone.svg"} alt={"phone img"} />
											<a href={`tel:${collaboratorData.phone}`} style={{ textDecoration: "none" }} className={"text-micro paragraph_bold color-primary"}>{collaboratorData.phone}</a>
										</div>
									</div>
									<div className="card-collaborator-project card-collaborator-project-progress">
										<p className={"text-micro paragraph_bold color-dark-grey marginbottom-8"}>Projets en cours</p>
										<div className="card-collaborator-project-list">
											{currentProject.length > 0 ?
												currentProject.map((progressItem, progressIndex) => {
													return (
														<div key={progressIndex}
															className="card-collaborator-project-list-item"
															onClick={() => {
																selectedProgressItem(progressItem);
																//setSelectedProgressItem,
															}}
														>
															<p className="text-primary color-dark-grey">
																{`#${progressItem.project.client.reference} - ${progressItem.project.address.street}, ${progressItem.project.address.nicePostcode}, ${progressItem.project.address.city}  - ${progressItem.title}`}
															</p>
															<div className="form-row-label-input-tag-item-cross"
																onClick={(e) => {
																	e.stopPropagation();
																	setShowPopUpDeleteProject(true);
																	selectedProgressItem(progressItem);
																	setSelectedProject(progressItem.id)
																}}>
																<img src="/images/pictos/cross-background-red.svg" alt="" />
															</div>
														</div>
													)
												}) : <p className="text-primary color-primary">Aucun projet en cours</p>}
											<Button
												title={"Attribuer un projet"}
												isActive={true}
												styleButton={"btn-default btn-default-mini btn-default-secondary"}
												onClick={setShowPopUpAssignProject}
											/>
										</div>
									</div>
									<div className={"form-row-element"}>
										<InputLabelErrorMessage
											keyLabel={"label"}
											label="Adresse du bureau"
											keyValue={"value"}
											name="workingRegion"
											type="select"
											data={[
												{ label: "IDF", value: "IDF" },
												{ label: "MEL", value: "MEL" },
											]}
										/>
									</div>

									<div className={"form-row-element"}>
										<InputLabelTagIngErrorMessage
											type={"select"}
											name={"generalAbilities"}
											label={"Compétences techniques"}
											placeholder={"Ajouter une compétence"}
											keyLabel={"title"}
											keyValue={"title"}
											keyType={"color"}
											dataTag={generalAbilities}
											data={allCompetences}
											onClick={(title) => deleteCompetenceGeneralAbilities(title)}
											onChange={(e) => updateCompetenceGeneralAbilities(e.target.value)}
										/>
									</div>
									<div className={"form-row-element"}>
										<InputLabelTagIngErrorMessage
											type={"select"}
											name={"visitAbilities"}
											label={"Compétences de visite"}
											placeholder={"Ajouter une compétence de visite"}
											keyLabel={"title"}
											keyValue={"title"}
											keyType={"color"}
											dataTag={visitAbilities}
											data={allVisitAbilites}
											onClick={(title) => deleteCompetenceVisitAbilities(title)}
											onChange={(e) => updateCompetenceVisitAbilities(e.target.value)}
										/>
									</div>
									<div className={"form-row-element"}>
										<InputLabelTagIngErrorMessage
											type={"select"}
											name={"visitTransportations"}
											label={"Méthodes de transport"}
											placeholder={"Ajouter une méthode de transport"}
											keyLabel={"title"}
											keyValue={"title"}
											keyType={"color"}
											dataTag={visitTransportations}
											data={allVisitTransportations}
											onClick={(title) => deleteCompetenceVisitTransportations(title)}
											onChange={(e) => updateCompetenceVisitTransportations(e.target.value)}
										/>
									</div>
									<ButtonSubmit title={"Attribuer"}
										isActive={false}
										styleButton={"btn-default btn-default-secondary plain"}
									/>
								</Form>)}
						</Formik>
						{
							showPopUpDeleteProject ? (
								<PopUpDeleteProjectCollaborator
									onClickClose={() => setShowPopUpDeleteProject(false)}
									onClickDelete={() => deleteProjectProgress()}
								/>
							) : null
						}
					</>
				)
			}

		</>
	);
};

export default FormIngCollaborator;
