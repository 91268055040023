import api from "../../APIService";
import {ConvertObjectToParams, ConvertObjectToUrlParams} from "../../../utils/StringUtils";

const getApprovalCircuit = async () => {
	return await api.getAllData('projects/context/engineer-validation')
}

const getAllCollaborators = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('ings', param))
}

const getAllCollaboratorsHighRoles = async (param) => {
	return await api.getAllData(ConvertObjectToParams('ings?roles[]=ROLE_ING_MANAGER&roles[]=ROLE_ING_AFF&roles[]=ROLE_ING_DIAG&roles[]=ROLE_ING_VERIF&roles[]=ROLE_ING_EXTRA', param))
}

const deleteProjectCollaborator = async (id) => {
	return await api.del(`tasks/${id}`)
}

//KPI
const getProjectInProgress = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('kpi/engineer/active-projects-count', param))
}

const getProjectFinished = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('kpi/engineer/ing-projects-count', param))
}

const getNumberAssetsEdited = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('kpi/engineer/credits-count', param))
}

const getNumberFolderWaitingPayment = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('kpi/engineer/processing-final-invoices-count', param))
}

const getNumberProjectInRecovery = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('kpi/engineer/canceled-projects-count', param))
}

const getNumberFoderLate = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('kpi/engineer/overdue-studies-count', param))
}

const getStatusStudyFolder = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('kpi/engineer/studies-by-control', param))
}

const getCAByProject = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('kpi/engineer/revenues-by-stage', param))
}

const getTimeProduction = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('kpi/engineer/production-hours', param))
}

const getCAGlobal = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('kpi/engineer/revenues', param))
}

// FIN KPI

const getAllProjects = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('projects', param))
}

const addCollaborator = async (data) => {
	return await api.postBody('ings', data)
}

const getOneCollaborator = async (id) => {
	return await api.getAllData(`ings/${id}`)
}

const patchOneCollaborator = async (id, data) => {
	return await api.patch(`ings/${id}`, data)
}


const getObjectifTemp = async (param) => {
	return await api.getAllData(ConvertObjectToParams('projects/context/engineer?stage.title[]=COM_delivery&stage.title[]=ING_correction-study&stage.title[]=COM_feedback&stage.title[]=ADMIN_refund&stage.title[]=COM_payment&stage.title[]=ADMIN_payment-follow-up', param))
}

const getControls = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('projects/context/engineer-validation', param))
}

const archiveProject = async (id, data) => {
	return await api.patch(`studies/${id}/engineer-validate`, data)
}

const getInformationTSR = async (id) => {
	return await api.getAllData(`studies/${id}/study-summaries`)
}

const postTsr = async (id, data) => {
	return await api.patch(`studies/${id}/study-summaries`, data)
}

const getCurrentProject = async (id) => {
	return await api.getAllData(`assignments?user.id=${id}&completed=false&project.stage.title=ING`)
}

const getAllUnassignedProjects = async (param) => {
	return await api.getAllData(ConvertObjectToParams(`projects/context/engineer?stage.title[]=ING_visit&stage.title[]=ING_report-visit&stage.title[]=ING_pri&stage.title[]=ING_study&stage.title[]=ING_verification-study&stage.title[]=ING_correction-study&pagination=false`, param))
}

const getUsersByRole = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`users`, param))
}

const getAssignements = async (id) => {
	return await api.getAllData(`projects/${id}/assignments`)
}

const patchAssignements = async (id, data) => {
	return await api.patch(`projects/${id}/assignments`, data)
}

const patchCreditRequest = async (id, data) => {
	return await api.patch(`projects/${id}/context/engineer`, data)
}

const getAllClients = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('clients', param))
}

const postAssignements = async (data) => {
	return await api.postBody(`assignments`, data)
}

const unassignProject = async (id) => {
	return await api.del(`tasks/${id}`)
}

// Study Folder

const getStudyFolderInformations = async (id) => {
	return await api.getAllData(`studies/${id}`)
}

const patchStudyFolderCKEDITOR = async (id, data) => {
	return await api.patch(`studies/${id}`, data)
}

const sendToValidation = async (id, data) => {
	return await api.patch(`studies/${id}`, data)
}

const getStudyFolderImg = async (id, param) => {
	return await api.getAllData(ConvertObjectToUrlParams(`projects/${id}/medias`, param))
}

const getDPGF = async (id) => {
	return await api.getAllData(`studies/${id}/study-dpgfs`)
}

const getCCTP = async (param) => {
	return await api.getAllData(ConvertObjectToParams(`categories?type=cctp&title=2.`, param))
}

const getDPGFForTable2 = async (id) => {
	return await api.getAllData(`dpgfs?cctp.category.id=${id}`)
}

const sendDPGF = async (id, data) => {
	return await api.patch(`studies/${id}/study-dpgfs`, data)
}

// CKEDITOR

// Recover thread 

const getAllThread = async (id) => {
	return await api.getAllData(`studies/${id}/editor-comments`)
}

const addNewThread = async (id, data) => {
	return await api.postBody(`studies/${id}/editor-comments`, data)
}

const removeComment = async (id) => {
	return await api.del(`editor-comments/${id}`)
}

const editComment = async (id, data) => {
	return await api.patch(`editor-comments/${id}`, data)
}

const removeThread = async (studyId, threadId) => {
	return await api.del(`studies/${studyId}/editor-threads/${threadId}`)
}

const manageThread = async(studyId, threadId, data) => {
	return await api.patch(`studies/${studyId}/editor-threads/${threadId}`, data)
}

// CCTP

const getStudyFolderCCTP = async (id) => {
	return await api.getAllData(`studies/${id}/study-cctps`)
}

const sendCCTP = async (id, data) => {
	return await api.patch(`studies/${id}/study-cctps`, data)
}

// Annexe

const getAnnexe = async (id) => {
	return await api.getAllData(`studies/${id}/appendices`)
}

const patchAnnexe = async (id, data) => {
	return await api.patch(`studies/${id}/appendices`, data)
}

const sendStudyFolderToVerification = async (id, data) => {
	return await api.patch(`studies/${id}`, data)
}

const getAllChat = async (param) => {
	return await api.getAllData(ConvertObjectToUrlParams('chats', param))
}

const getInterneMessages = async (id) => {
	return await api.getAllData(`company-chats/${id}`)
}

const getClientMessages = async (id) => {
	return await api.getAllData(`client-chats/${id}`)
}

const sendMessageToClient = async (id, data) => {
	return await api.postBody(`projects/${id}/client-chats`, data)
}

const sendMessageToInterne = async (id, data) => {
	return await api.postBody(`projects/${id}/company-chats`, data)
}

//Execution Class
const getExecutionClass = async(id) => {
	return await api.getAllData(`studies/${id}/study-execution-class`)
}

const patchExecutionClass = async (id, data) => {
	return await api.patch(`studies/${id}/study-execution-class`, data)
}

const getComments = async (id) => {
	return await api.getAllData(`/projects/${id}/comments`)
}

const DirRespService = {
	getApprovalCircuit,
	getAllCollaborators,
	getAllCollaboratorsHighRoles,
	getProjectInProgress,
	getProjectFinished,
	getNumberAssetsEdited,
	getNumberFolderWaitingPayment,
	getNumberProjectInRecovery,
	getNumberFoderLate,
	getStatusStudyFolder,
	getCAByProject,
	getTimeProduction,
	getCAGlobal,
	getAllProjects,
	addCollaborator,
	getOneCollaborator,
	patchOneCollaborator,
	getObjectifTemp,
	getControls,
	archiveProject,
	getInformationTSR,
	postTsr,
	getCurrentProject,
	getAllUnassignedProjects,
	getUsersByRole,
	getAssignements,
	patchAssignements,
	patchCreditRequest,
	getAllClients,
	postAssignements,
	deleteProjectCollaborator,
	unassignProject,
	// Study Folder
	getStudyFolderInformations,
	patchStudyFolderCKEDITOR,
	sendToValidation,
	getStudyFolderImg,
	getDPGF,
	getCCTP,
	getDPGFForTable2,
	sendDPGF,
	getStudyFolderCCTP,
	sendCCTP,
	getAnnexe,
	patchAnnexe,
	sendStudyFolderToVerification,
	//chat
	getAllChat,
	getInterneMessages,
	getClientMessages,
	sendMessageToClient,
	sendMessageToInterne,
	//Ckeditor
	getAllThread,
	addNewThread,
	removeComment,
	removeThread,
	editComment,
	manageThread,
	//Execution class
	getExecutionClass,
	patchExecutionClass,
	getComments,
}

export default DirRespService
