export const dataTypologies = [
    {
        "label": "Construction neuve",
        "value": "project-cn"
    },
    {
        "label": "Création de mezzanine",
        "value": "project-cdm"
    },
    {
        "label": "Dépose de cheminée",
        "value": "project-ddc"
    },
    {
        "label": "Détermination de capacité portante",
        "value": "project-dcp"
    },
    {
        "label": "Diagnostic fissuration",
        "value": "project-diag-fiss"
    },
    {
        "label": "Diagnostic post-travaux",
        "value": "project-diag-pt"
    },
    {
        "label": "Diagnostic structure",
        "value": "project-diag-str"
    },
    {
        "label": "Extension",
        "value": "project-ext"
    },
    {
        "label": "Modification de charpente",
        "value": "project-mdc"
    },
    {
        "label": "Ouverture de mur porteur",
        "value": "project-omp"
    },
    {
        "label": "Ouverture de plancher",
        "value": "project-odp"
    },
    {
        "label": "Pathologie du bâtiment",
        "value": "project-pdb"
    },
    {
        "label": "Renforcement de plancher",
        "value": "project-rdp"
    },
    {
        "label": "Reprise en sous-œuvre",
        "value": "project-rso"
    },
    {
        "label": "Surélévation",
        "value": "project-sur"
    }
  ];
  