const ProjectNiceStage = ({ stage, niceStage, canceledAt }) => {
  switch (stage) {
    case 'COM_commercial-exchange':
    case 'COM_quotation':
    case 'COM_discount-validation':
      return 'Édition du devis'
    case 'ADMIN_validate-visit':
      return 'Réservation de visite'
    case 'ING_study':
    case 'ING_verification-study':
    case 'ING_validation-study':
      return 'Etude en cours'
    case 'ADMIN_refund':
      return "Génération d'avoir"
    case 'COM_feedback':
      return canceledAt ? 'Projet archivé' : 'Projet finalisé';
    case 'COM_payment':
    case 'COM_deposit':
      return 'En attente de paiement'
    case 'COM_delivery':
      return 'Paiement final reçu'
    default:
      return niceStage;
  }
}

export default ProjectNiceStage;