import React, { useState } from "react";
import { Link } from "react-router-dom";
import FormEditPrevTime from "../../../views/Pages/Coms/Dir/Collaborators/Forms/FormEditPrevTime";
import PopUpForm from "../../../components/Molecules/PopUp/PopUpForm";
import PopUpObjectifsTemporels from "../../../components/Molecules/PopUp/PopUpObjectifsTemporels";

export const OBJECTIFS_TEMPORELS_COLUMNS = (filteredUser) => [
  {
    Header: "Référence dossier",
    accessor: "reference",
    Cell: (cellInfo) => {
      return (
        <Link to={`/projet/${cellInfo.row.original.id}`}>
          {cellInfo.row.original.reference}
        </Link>
      );
    },
  },
  {
    Header: "Nom client",
    accessor: "niceNames",
    Cell: (cellInfo) => {
      return (
        <Link to={`/client/${cellInfo.row.original.client.id}`}>
          {cellInfo.row.original.client.niceNames}
        </Link>
      );
    },
  },
  {
    Header: "Collaborateur",
    Cell: (cellInfo) => filteredUser.niceNames,
  },
  {
    Header: "Rôle",
    Cell: (cellInfo) => filteredUser.highestRole.replace("ROLE_", ""),
  },
  {
    Header: "Temps prévisionnel",
    accessor: "userEstimatedHours",
    Cell: (cellInfo) => {
      const heures = Math.floor(cellInfo.row.original.userEstimatedHours);
      const decimalPart = cellInfo.row.original.userEstimatedHours - heures;
      const minutes = Math.round(decimalPart * 60);

      if (heures === 0) {
        return (
          <div>
            {minutes < 10 ? "0" : ""}
            {minutes} min
          </div>
        );
      } else {
        return (
          <div>
            {heures}h{minutes < 10 ? "0" : ""}
            {minutes}
          </div>
        );
      }
    },
  },
  {
    Header: "Temps réel",
    accessor: "userEffectiveHours",
    Cell: (cellInfo) => {
      const heures = Math.floor(cellInfo.row.original.userEffectiveHours);
      const decimalPart = cellInfo.row.original.userEffectiveHours - heures;
      const minutes = Math.round(decimalPart * 60);

      if (heures === 0) {
        return (
          <div>
            {minutes < 10 ? "0" : ""}
            {minutes} min
          </div>
        );
      } else {
        return (
          <div>
            {heures}h{minutes < 10 ? "0" : ""}
            {minutes}
          </div>
        );
      }
    },
  },
  {
    Header: "Écart",
    accessor: "Ecart",
    Cell: (cellInfo) => {
      let ecart =
        cellInfo.row.original.userEstimatedHours -
        cellInfo.row.original.userEffectiveHours;
      const heures = Math.floor(ecart);
      const decimalPart = ecart - heures;
      const minutes = Math.round(decimalPart * 60);
      if (heures === 0) {
        return <div>{minutes} min</div>;
      } else {
        return (
          <div>
            {heures}h{minutes < 10 ? "0" : ""}
            {minutes}
          </div>
        );
      }
    },
  },
  {
    Header: "Coût réel",
    accessor: "Coût réel",
    Cell: (cellInfo) => {
      return (
        <div>
          {(
            cellInfo.row.original.userEffectiveHours *
            filteredUser.rateH || 0
          ).toFixed(2)}{" "}
          €
        </div>
      );
    },
  },
  {
    Header: "Taux marge",
    accessor: "TauxMarge",
    Cell: (cellInfo) => {
      let tauxMarge =
        ((cellInfo.row.original.userEstimatedHours -
          cellInfo.row.original.userEffectiveHours) /
          cellInfo.row.original.userEstimatedHours) *
        100;
      return <div>{tauxMarge ? tauxMarge.toFixed(2) : "0.00"} %</div>;
    },
  },
  {
    Header: "Actions",
    accessor: "",
    Cell: (cellInfo) => {
      const [isActive, setIsActive] = useState(false);
      const [isActiveComment, setIsActiveComment] = useState(false);
      
      return (
        <>
          <div className={"actions-controller"}>
            {
              cellInfo.row.original.hasComment ? (
                <img alt={"commentaire"} src={"/images/pictos/message-circle-blue.svg"} title={"Commentaires"} onClick={() => {
                  setIsActiveComment(true)
                }}/>
              ) : null
            }
            <img alt="editer" src={"/images/pictos/pencil-blue.svg"} title={"Editer"} onClick={() => {
              setIsActive(true)
            }}/>
        </div>
        {
          isActiveComment ? (
            <PopUpObjectifsTemporels onClick={() => setIsActiveComment(false)} id={cellInfo.row.original.id} />
          ) : null
        }
        <PopUpForm large={true} title={"Modifier le temps prévisionnel et le temps réel"} form={<FormEditPrevTime filteredUser={filteredUser} id={cellInfo.row.original.id} realTime={cellInfo.row.original.userEffectiveHours} prevTime={cellInfo.row.original.userEstimatedHours} setShowPopUp={setIsActive}  />} onClick={() => setIsActive(false)}  show={isActive}   />
        </>
      )
    }
  }
];
