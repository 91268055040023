import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import ButtonSubmit from "../../../../../../components/Atomes/Buttons/ButtonSubmit";
import { InitialValuesProfilJobAccount } from "./Config/InitialValuesProfilJobAccount";
import { SchemaProfileIngJobAccount } from "./Config/SchemaProfileIngJobAccount";
import api from "../../../../../../services/APIService";
import InputLabelTagIngErrorMessage from "../../../../../../components/Atomes/Formik/InputLabelTagIngErrorMessage";
import axios from "axios";
import InputLabelErrorMessage from '../../../../../../components/Atomes/Formik/InputLabelErrorMessage';

const ProfileJobForm = () => {

	const [preview, setPreview] = useState(null);
	const [userJob, setUserJob] = useState(null);
	const [generalAbilities, setGeneralAbilities] = useState([]);
	const [visitAbilities, setVisitAbilities] = useState([]);
	const [visitTransportations, setVisitTransportations] = useState([]);
	const [userRole, setUserRole] = useState(null);

	const allCompetences = [
		{ "name": "Autocad", "color": "#58586A" },
		{ "name": "Autocad LT", "color": "#6F6A97" },
		{ "name": "Robot Structural Analysis", "color": "#9D8DF1" },
		{ "name": "Graitec Arche Hybride", "color": "#ABADF5" },
		{ "name": "SCIA Engineer", "color": "#CFC8D9" },
		{ "name": "Béton", "color": "#E1D6CB" },
		{ "name": "Bois", "color": "#F2E3BC" },
		{ "name": "Mixte", "color": "#A55F7C" },
		{ "name": "Charpentes métalliques", "color": "#DB5461" },
		{ "name": "Métal", "color": "#E79C8F" },
		{ "name": "Sika CarboDur", "color": "#519E8A" },
		{ "name": "Débutant", "color": "#A2C1A3" },
		{ "name": "Junior", "color": "#CAD2B0" },
		{ "name": "Confirmé", "color": "#466060" },
		{ "name": "Senior", "color": "#9CA28E" },
		{ "name": "Béton précontraint", "color": "#4F7466" },
		{ "name": "Diagnostic pathologie du bâtiment", "color": "#4357AD" },
		{ "name": "Ingénieur diagnostiqueur", "color": "#48A9A6" }
	];

	const allVisitAbilites = [
		{ "name": "Habilité sondage", "color": "#58586A" },
		{ "name": "Gants (EPI)", "color": "#6F6A97" },
		{ "name": "Chaussures (EPI)", "color": "#9D8DF1" },
		{ "name": "Pantalon (EPI)", "color": "#ABADF5" },
		{ "name": "Polo (EPI)", "color": "#CFC8D9" },
		{ "name": "Veste (EPI)", "color": "#E1D6CB" },
		{ "name": "Casque (EPI)", "color": "#F2E3BC" },
		{ "name": "Casquette coquée (EPI)", "color": "#A55F7C" },
	];

	const allVisitTransportations = [
		{ "name": "Vélo", "color": "#58586A" },
		{ "name": "Vélo cargo", "color": "#6F6A97" },
		{ "name": "Train", "color": "#9D8DF1" },
		{ "name": "Voiture de fonction", "color": "#ABADF5" },
		{ "name": "Voiture de service", "color": "#CFC8D9" },
		{ "name": "Transport en commun", "color": "#E1D6CB" },
		{ "name": "Scooter de fonction", "color": "#F2E3BC" },
	];

	const handleFileChange = (event, setFieldValue) => {
		const file = event.target.files[0];
		setFieldValue('signature', file);
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreview(reader.result);
			};
			reader.readAsDataURL(file);
		} else {
			setPreview(null);
		}
	};

	const deleteCompetenceGeneralAbilities = (title) => {
		let competencesTagUpdate = generalAbilities.filter(competenceTag => competenceTag.title !== title)
		setGeneralAbilities(competencesTagUpdate)
	}

	const updateCompetenceGeneralAbilities = (e) => {
		setGeneralAbilities([...generalAbilities, { title: e, color: "red" }])
	}

	const deleteCompetenceVisitAbilities = (title) => {
		let competencesTagUpdate = visitAbilities.filter(competenceTag => competenceTag.title !== title)
		setVisitAbilities(competencesTagUpdate)
	}

	const updateCompetenceVisitAbilities = (e) => {
		setVisitAbilities([...visitAbilities, { title: e, color: "red" }])
	}

	const deleteCompetenceVisitTransportations = (title) => {
		let competencesTagUpdate = visitTransportations.filter(competenceTag => competenceTag.title !== title)
		setVisitTransportations(competencesTagUpdate)
	}

	const updateCompetenceVisitTransportations = (e) => {
		setVisitTransportations([...visitTransportations, { title: e, color: "red" }])
	}

	const getUserData = async () => {
		const userId = localStorage.getItem("userId");
		if (userId != null) {
			let request = await api.get(`ings/${userId}`);
			setUserJob(request);
			setGeneralAbilities(request.generalAbilities);
			setVisitAbilities(request.visitAbilities);
			setVisitTransportations(request.visitTransportations);
			setUserRole(request.roles[0]);
			InitialValuesProfilJobAccount.workingRegion = request.workingRegion;

			if (request.urlSignature) {
				InitialValuesProfilJobAccount.signature = request.urlSignature;
				setPreview(`${process.env.REACT_APP_PROD_URL}${request.urlSignature}`);
			}
		}
	}

	const onSubmit = async (values, actions) => {
		const user = JSON.parse(JSON.parse(localStorage.getItem("user")))
		const userId = localStorage.getItem("userId");
		values.generalAbilities = generalAbilities
		values.visitAbilities = visitAbilities
		values.visitTransportations = visitTransportations

		const updatedData = {
			generalAbilities: values.generalAbilities.map(tag => {
				const { color, ...rest } = tag;
				return rest;
			}),
			visitAbilities: values.visitAbilities.map(tag => {
				const { color, ...rest } = tag;
				return rest;
			}),
			visitTransportations: values.visitTransportations.map(tag => {
				const { color, ...rest } = tag;
				return rest;
			}),
			workingRegion: values.workingRegion,
		};

		await api.patch(`ings/${userId}`, {
			generalAbilities: updatedData.generalAbilities, 
			visitAbilities: updatedData.visitAbilities, 
			visitTransportations: updatedData.visitTransportations,
			roles: [userRole], 
			workingRegion: updatedData.workingRegion 
		})

		if (typeof values.signature !== 'string') {

			const file = new FileReader();
			file.readAsDataURL(values.signature);

			const requete = axios.create({
				baseURL: process.env.REACT_APP_PROD_API_URL,
				headers: {
					Authorization: `Bearer ${user ? user.token : ""}`,
					'Content-Type': `multipart/form-data`
				}
			})

			const updateSignature = await requete.post(`ings/${userId}/signature`, { file: values.signature })
			if (updateSignature.status === 201) {
				window.location.reload();
			}
		}
	};

	useEffect(() => {
		getUserData();
	}, []);

	return (
		<div className='mbe-profile-container mbe-section'>
			<h3 className='color-primary'>Mes informations professionnelles</h3>
			<Formik
				initialValues={InitialValuesProfilJobAccount}
				validationSchema={SchemaProfileIngJobAccount}
				onSubmit={onSubmit}
			>
				{({ isSubmitting, setFieldValue }) => (
					<Form>
						<div className={"form-row-element"}>
							<InputLabelErrorMessage
								keyLabel={"label"}
								label="Adresse du bureau"
								keyValue={"value"}
								name="workingRegion"
								type="select"
								data={[
									{ label: "IDF", value: "IDF" },
									{ label: "MEL", value: "MEL" },
								]}
							/>
						</div>

						<div className={"form-row-element"}>
							<InputLabelTagIngErrorMessage
								type={"select"}
								name={"generalAbilities"}
								label={"Compétences techniques"}
								placeholder={"Ajouter une compétence"}
								keyLabel={"title"}
								keyValue={"title"}
								keyType={"color"}
								dataTag={generalAbilities}
								data={allCompetences}
								onClick={(title) => deleteCompetenceGeneralAbilities(title)}
								onChange={(e) => updateCompetenceGeneralAbilities(e.target.value)}
							/>
						</div>
						<div className={"form-row-element"}>
							<InputLabelTagIngErrorMessage
								type={"select"}
								name={"visitAbilities"}
								label={"Compétences de visite"}
								placeholder={"Ajouter une compétence de visite"}
								keyLabel={"title"}
								keyValue={"title"}
								keyType={"color"}
								dataTag={visitAbilities}
								data={allVisitAbilites}
								onClick={(title) => deleteCompetenceVisitAbilities(title)}
								onChange={(e) => updateCompetenceVisitAbilities(e.target.value)}
							/>
						</div>
						<div className={"form-row-element"}>
							<InputLabelTagIngErrorMessage
								type={"select"}
								name={"visitTransportations"}
								label={"Méthodes de transport"}
								placeholder={"Ajouter une méthode de transport"}
								keyLabel={"title"}
								keyValue={"title"}
								keyType={"color"}
								dataTag={visitTransportations}
								data={allVisitTransportations}
								onClick={(title) => deleteCompetenceVisitTransportations(title)}
								onChange={(e) => updateCompetenceVisitTransportations(e.target.value)}
							/>
						</div>

						<div className={"form-row-element signature"}>
							<label className={"text-micro paragraph_bold color-dark-grey"}>Signature</label>
							<div className={"signature-input"}>
								<img src={"/images/pictos/ing-job-signature.svg"} alt={"img edit signature"}
									className={"signature-input-absolute"} />
								<label htmlFor="signature" style={{ cursor: 'pointer' }}>
									{preview ? (
										<img
											className={"signature-input-card"}
											style={{ width: '344px', height: '94px', border: '2px solid #648DE5', objectFit: 'contain' }}
											src={preview}
											alt="Signature preview"
										/>
									) : (
										<div style={{ width: '344px', height: '94px', border: '2px solid #648DE5' }}
											className={"signature-input-card"}>
											<p className={"text-primary color-primary"}>Cliquez pour sélectionner une image</p>
										</div>
									)}
									<input
										id="signature"
										name="signature"
										type="file"
										style={{ display: 'none' }}
										onChange={(event) => handleFileChange(event, setFieldValue)}
									/>
								</label>
							</div>
							<ErrorMessage name="signature" component="p" className={"color-error text-primary"} />
						</div>

						<ButtonSubmit
							styleButton={"btn-default btn-default-secondary plain"}
							isActive={isSubmitting} title={"Sauvegarder"}
						/>
					</Form>
				)
				}
			</Formik>
		</div >
	)
}

export default ProfileJobForm;