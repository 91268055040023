import React, { useContext, useEffect, useState } from 'react';
import { Form, Formik } from "formik";
import ButtonSubmit from "../../../../../../components/Atomes/Buttons/ButtonSubmit";
import InputLabelErrorMessage from "../../../../../../components/Atomes/Formik/InputLabelErrorMessage";
import CollaboratorService from "../../../../../../services/CollaboratorService";
import { StateRequest } from '../../../../Ings/Dir/Objectifs/ObjectifsViewIngDir';
import SchemaObjectiveTemp from '../../../../../../components/Molecules/Cards/Collaborator/SchemaObjectiveTemp';

const FormEditPrevTime = ({ id, setShowPopUp, prevTime, realTime, filteredUser }) => {

	const { stateRequest, setStateRequest } = useContext(StateRequest);

	const [hoursPrev, setHoursPrev] = useState("");
	const [minutesPrev, setMinutesPrev] = useState("");

	const [hoursReal, setHoursReal] = useState("");
	const [minutesReal, setMinutesReal] = useState("");

	const floatToTime = (float) => {
		const hours = Math.floor(float); // Partie entière pour les heures
		const minutes = Math.round((float - hours) * 60); // Partie décimale convertie en minutes

		// Formatage en HH:MM avec des zéros devant si nécessaire
		const formattedHours = String(hours).padStart(2, '0');
		const formattedMinutes = String(minutes).padStart(2, '0');
		
		return [formattedHours, formattedMinutes];
	};

	const timeToFloat = (time) => {
		const [hours, minutes] = time.split(':').map(Number);
		const floatTime = hours + (minutes / 60);
		return parseFloat(floatTime.toFixed(4)); // Arrondi à 2 décimales
	}

	const onSubmit = async (values) => {

		let effectivePrevDurationFloat = timeToFloat(hoursPrev + ":" + minutesPrev);
		let effectiveRealDurationFloat = timeToFloat(hoursReal + ":" + minutesReal);

		const request = await CollaboratorService.patchPrevTemp(`projects/${id}/context/engineer/change-duration`, {
			"estimatedDuration": effectivePrevDurationFloat,
			"effectiveDuration": effectiveRealDurationFloat,
			"userIri": `/api/users/${filteredUser.id}`,
		});
		if (request.request.status === 200) {
			setStateRequest("success edit");
		} else {
			setStateRequest("error");
		}
		setShowPopUp(false);
	};

	useEffect(() => {
		// Convertir prevTime et realTime en format HH:MM
		const formattedPrevTime = floatToTime(prevTime);
		const formattedRealTime = floatToTime(realTime);

		initialValues.userPrevTime = formattedPrevTime;
		initialValues.userRealTime = formattedRealTime;
		
		setHoursPrev(formattedPrevTime[0]);
		setMinutesPrev(formattedPrevTime[1])

		setHoursReal(formattedRealTime[0]);
		setMinutesReal(formattedRealTime[1])
		
	}, [id]);

	const initialValues = {
		userPrevTime: floatToTime(prevTime), // Conversion initiale
		userRealTime: floatToTime(realTime), // Conversion initiale
	};

	const generateOptions = (max) => {
		const options = [];
		for (let i = 0; i <= max; i++) {
			options.push(
				<option key={i} value={i < 10 ? `0${i}` : i}>
					{i < 10 ? `0${i}` : i}
				</option>
			);
		}
		return options;
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
			>
				{({ isSubmitting }) => (
					<Form>
						<h5>Temps prévisionnel</h5>
						<div style={{ paddingTop: '5px', display: "flex", alignItems: "center" }}>
							<select
								value={hoursPrev}
								style={{ minWidth: "70px !important" }}
								onChange={(e) => setHoursPrev(e.target.value)}
								aria-label="Heures"
							>
								<option value="" disabled>Heures</option>
								{generateOptions(60)}
							</select>
							<h3> : </h3>
							<select
								value={minutesPrev}
								style={{ minWidth: "70px !important" }}
								onChange={(e) => setMinutesPrev(e.target.value)}
								aria-label="Minutes"
							>
								<option value="" disabled>Minutes</option>
								{generateOptions(59)}
							</select>
						</div>

						<h5 style={{marginTop: "10px"}}>Temps réel</h5>
						<div style={{ paddingTop: '5px', display: "flex", alignItems: "center" }}>
							<select
								value={hoursReal}
								style={{ minWidth: "70px !important" }}
								onChange={(e) => setHoursReal(e.target.value)}
								aria-label="Heures"
							>
								<option value="" disabled>Heures</option>
								{generateOptions(60)}
							</select>
							<h3> : </h3>
							<select
								value={minutesReal}
								style={{ minWidth: "70px !important" }}
								onChange={(e) => setMinutesReal(e.target.value)}
								aria-label="Minutes"
							>
								<option value="" disabled>Minutes</option>
								{generateOptions(59)}
							</select>
						</div>

						<ButtonSubmit styleButton={"btn-default btn-default-secondary plain"} isActive={isSubmitting} title={"Modifier"} />
					</Form>
				)}
			</Formik>
		</>
	);
};

export default FormEditPrevTime;
