import React, { createContext, useEffect, useState } from 'react';
import exportFileService from "../../../../../services/ExportFileService";
import moment from "moment";
import FilterTwoSelectsDatepicker from "../../../../../components/Molecules/Filter/FilterTwoSelectsDatepicker";
import { toast } from "react-toastify";
import DirRespService from "../../../../../services/Ing/DirResp/DirRespService";
import TitleSectionWithButton from "../../../../../components/Molecules/Title/TitleSectionWithButton";
import { SortingTable } from "../../../../../components/Molecules/Tables/SortingTable";
import { OBJECTIFS_TEMPORELS_COLUMNS } from "../../../../../config/ArrayColumns/Engineer/ObjectifsTemporelsColumns";
import TimeCalculated from "./Partials/TimeCalculated";
import { dataRegionsArray } from "../../../../../utils/RegionsUtils";

export const StateRequest = createContext()
const ObjectifsViewIngDir = () => {

	const downloadFile = () => {
		const date = moment().format("L").split("/").join("-")
		exportFileService.exportFile(`/engineer/${userFilter.id}/objectives`, `export-objectifs-${date}`, {
			region: region,
			dateStart: dateStart,
			dateEnd
		}, "xlsx")
	}

	const [objectiveLoading, setObjectiveLoading] = useState(false)


	const options = dataRegionsArray
	const [dateStart, setDateStart] = useState("");
	const [dateEnd, setDateEnd] = useState("");
	const [date, setDate] = useState(new Date());
	const [userFilter, setUserFilter] = useState(null)
	const [region, setRegion] = useState("");
	const [collaborators, setCollaborators] = useState([]);
	const [stateRequest, setStateRequest] = useState("");

	const [objectives, setObjectives] = useState([]);
	const [loading, setLoading] = useState(true);
	const [timeCalculatedCalc, setTimeCalculatedCalc] = useState({
		tempPrev: 0,
		tempReel: 0,
		tempEcart: 0,
		tauxMarge: 0,
		volume: 0,
		tauxAcquisition: 0,
		tauxProduction: 0,
	})
	let timeCalculated = {
		tempPrev: 0,
		tempReel: 0,
		tempEcart: 0,
		tauxMarge: 0,
		volume: 0,
		tauxAcquisition: 0,
		tauxProduction: 0,
	}

	const DisplayToast = (type) => {
		if (type === "success edit") {
			toast.success("Les modifications ont bien été enregistré")
		} else if (type === "error") {
			toast.error("Une erreur est survenue")
		}
		setStateRequest("")
	}

	const changeRegionFilter = (event) => {
		if (event.target.value === "Toutes les agences") {
			setRegion("")
		} else {
			setRegion(event.target.value)
		}
	}

	const changeDate = (date) => {
		setDate(date)
		setDateStart(moment(date[0]).format("YYYY-MM-DD"))
		setDateEnd(moment(date[1]).format("YYYY-MM-DD"))
	}

	const changeCollaboratorFilter = (event) => {
		if (event.target.value === "Sélection du collaborateur") {
			setUserFilter(null);
		} else {
			setUserFilter(collaborators.find(c => c.id == event.target.value));
		}
	}

	const getTimeCalculated = async () => {
		const request = await DirRespService.getObjectifTemp(
			{
				region: region,
				"users.id": userFilter.id,
				pagination: "false",
				"endAt[before]": dateEnd,
				"endAt[after]": dateStart
			}
		)
		if (request.request.status === 200) {
			/*setObjectives(request.data)*/
			setObjectiveLoading(true)
			setTimeCalculatedCalc({
				tempPrev: 0,
				tempReel: 0,
				tempEcart: 0,
				tempEcartNegative: false,
				tauxMarge: 0,
				volume: 0,
				tauxAcquisition: 0,
				tauxProduction: 0,
			})
			timeCalculated.tempPrev = 0
			timeCalculated.tempReel = 0
			timeCalculated.tempEcart = 0
			timeCalculated.tauxMarge = 0
			timeCalculated.tempEcartNegative = false

			// Vars
			let rateH = 0
			let presence = 0
			let baseH = 0

			// Calculate values for each objective
			request.data['hydra:member'].map(item => {
				timeCalculated.tempPrev += item.userEstimatedHours
				timeCalculated.tempReel += item.userEffectiveHours
				timeCalculated.tempEcart += item.userEstimatedHours - item.userEffectiveHours
				timeCalculated.tauxMarge += (item.userEstimatedHours - item.userEffectiveHours) / item.userEstimatedHours * 100
			})

			if (userFilter.rateH && !rateH) rateH = userFilter.rateH
			if (userFilter.presence && !presence) presence = userFilter.presence
			if (userFilter.baseH && !baseH) baseH = userFilter.baseH

			// Calculate global values
			timeCalculated.volume = timeCalculated.tempReel * rateH
			timeCalculated.tauxAcquisition = timeCalculated.tempReel / presence * baseH
			
			timeCalculated.tempPrev = convertirEnHeuresMinutes(timeCalculated.tempPrev)
			timeCalculated.tempReel = convertirEnHeuresMinutes(timeCalculated.tempReel)

			if(timeCalculated.tempEcart < 0) {
				timeCalculated.tempEcart = convertirEnHeuresMinutes(timeCalculated.tempEcart * -1)
				timeCalculated.tempEcartNegative = true
			} else {
				timeCalculated.tempEcart = convertirEnHeuresMinutes(timeCalculated.tempEcart)
			}

			setTimeCalculatedCalc(timeCalculated)

		} else {
			toast.error("Une erreur est survenue lors de la récupération des objectives")
		}
	}

	function convertirEnHeuresMinutes(temps) {
		// Extraire les heures entières
		const heures = Math.floor(temps);

		// Extraire les minutes en décimales
		const minutesDecimales = temps % 1;
		// Convertir les minutes décimales en minutes entières
		const minutes = Math.round(minutesDecimales * 60);

		// Formater les minutes pour inclure un zéro en cas de besoin
		const minutesFormatées = (minutes < 10 ? "0" : "") + minutes;

		// Si les heures sont zéro, ne les incluez pas dans la sortie
		const heuresFormatées = heures > 0 ? heures + "h" : "00h";

		// Renvoyer la représentation formatée du temps
		return heuresFormatées + minutesFormatées;
	}

	const resetDate = () => {
		setDate(new Date())
		setDateStart("")
		setDateEnd("")
	}

	const getCollaborators = async () => {
		const request = await DirRespService.getAllCollaborators({
			regions: region,
		})
		if (request.request.status === 200) {
			setCollaborators(request.data['hydra:member'])
		} else {
			toast.error("Une erreur est survenue lors de la récupération de liste des collaborateurs")
		}
	}

	const getObjectives = async () => {
		const request = await DirRespService.getObjectifTemp(
			{
				region: region,
				"users.id": userFilter.id,
				pagination: "true",
				"endAt[before]": dateEnd,
				"endAt[after]": dateStart
			}
		)
		if (request.request.status === 200) {
			setObjectives(request.data)
			if (request.data['hydra:member'].length === 0) {
				toast.info("Aucun objectif n'a été trouvé")
			} else {
				setLoading(false)
			}
			setObjectiveLoading(true)
		} else {
			toast.error("Une erreur est survenue lors de la récupération des objectives")
		}
	}

	useEffect(() => {
		setLoading(true)
		if (userFilter) {
			getObjectives()
			getTimeCalculated()
		}

		if (stateRequest !== "") {
			DisplayToast(stateRequest)
		}
		getCollaborators()
	}, [region, userFilter, dateStart, dateEnd, stateRequest]);

	return (
		<StateRequest.Provider value={{ stateRequest, setStateRequest }}>
			<div className="mbe-section">
				<FilterTwoSelectsDatepicker
					defaultValue={"Toutes les agences"}
					options={options}
					firstValue={options}
					secondDefaultValue={"Sélection du collaborateur"}
					secondOptions={collaborators.map(collaborators => collaborators.lastname)}
					secondValue={collaborators.map(collaborators => collaborators.id)}
					onChange={changeRegionFilter}
					onChangeDate={(date) => changeDate(date)}
					onSecondChange={changeCollaboratorFilter}
					date={date}
					resetDateSelected={resetDate}

				/>
				<div>
					{
						objectiveLoading && userFilter && objectives !== [] ? (
							<>
								<TitleSectionWithButton title={"Objectifs temporels"}
									buttonTitle={"Exporter"}
									isActive={true}
									styledButton={"btn-default btn-default-blue btn-download color-primary"}
									onClick={downloadFile}
									className={"marginbottom-20"}
								/>
								{
									!loading ?
										(
											<SortingTable className={"section-array-revival mbe-section"}
												dataTable={objectives}
												columnsTable={OBJECTIFS_TEMPORELS_COLUMNS(userFilter)}
												numberElement={20}
											/>
										) : null
								}

							</>
						) : (
							<h4 className={"text-primary color-primary"}>Veuillez sélectionner un collaborateur</h4>
						)
					}


					{
						!loading ? (
							<TimeCalculated data={timeCalculatedCalc} />
						) : null
					}
				</div>
			</div>
		</StateRequest.Provider>
	);
};

export default ObjectifsViewIngDir;
