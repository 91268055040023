export const initialValuesDevis =

	{
		address :{
			street: "",
			city: "",
			postcode :"",
		},
		description :"",
		visit : "",
		client: {
			gender: "",
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
			address: {
				street: "",
				postcode: "",
				city: "",
				detail: ""
			},
			agencyName: ""
		},
		archIri : "",
		quotes: [
		],
		partnerQuotes: [
		],
		optionalContract : false,
	}
