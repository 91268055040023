import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { toast } from "react-toastify";

import MbeCalendar from '../../../../../../components/Molecules/Calendar/MbeCalendar';
import { ConvertTasksToFullcalendarEvents } from '../../../../../../utils/TaskUtils';

import TaskService from '../../../../../../services/TaskService';
import getUserService from '../../../../../../services/getUser';

import PopUpForm from '../../../../../../components/Molecules/PopUp/PopUpForm';
import FormAddAssignment from '../../../../../../components/Molecules/Form/MbeCalendar/FormAddAssignment';
import FormEditAssignment from '../../../../../../components/Molecules/Form/MbeCalendar/FormEditAssignment';
import {dataRegionsArray} from "../../../../../../utils/RegionsUtils";

const HomeIngManCalendar = () => {

    const userId = getUserService.getUserId();
    const nextYear = moment().add(1, 'y').format('YYYY-MM-DD')
    const [tasks, setTasks] = useState([]);
    const [selectValue, setSelectValue] = useState(userId);
    const [taskManager, setTaskManager] = useState(true);

    // "Collaborators" filter settings
    const [collaborators, setCollaborators] = useState([]);
    const getCollaborators = async () => {
        let request = await TaskService.getTasksUser("ings", { regions: region })
        if (request.request.status === 200) {
            let collaborators = request.data['hydra:member'];
            collaborators = collaborators.filter(collaborator => collaborator.id !== userId)
            setCollaborators(collaborators)
        }
    }
    const filterByCollaborator = (event) => {
        setSelectValue(event.target.value)
        if (event.target.value === userId.toString()) {
            setTaskManager(true)
            setSelectValue(userId)
        } else {
            if (event.target.value === "all") {
                setTaskManager(false)
                setSelectValue("")
            } else {
                setSelectValue(event.target.value)
                setTaskManager(false)
            }
        }
    }

    const firstSelectDefaultValue = "Toutes les agences";
    const firstSelectValue = dataRegionsArray;
    const firstSelectOptions = dataRegionsArray;
    const [region, setRegion] = useState("");
    const filterByRegion = (event) => {
        if (event.target.value === "Toutes les agences") {
            setRegion("")
        } else {
            setRegion(event.target.value)
        }
    }

    // "PopUp add" settings
    const [showPopupAdd, setShowPopupAdd] = useState(false);
    const [newTask, setNewTask] = useState([]);
    const DisplayCreateModalTask = (event) => {
        setShowPopupAdd(!showPopupAdd)
        if (!showPopupAdd) {
            setNewTask(event)
        }
    }

    // "PopUp edit" settings
    const [showPopupEdit, setShowPopupEdit] = useState(false);
    const [editTask, setEditTask] = useState([]);
    const DisplayEditModalTask = (event) => {
        setShowPopupEdit(!showPopupEdit)
        if (!showPopupEdit) {
            setEditTask(event)
        }
    }

    useEffect(() => {
        getCollaborators()
        const getTasks = async () => {
            let extraParams = "?category.type[]=task-project-ing-writing&category.type[]=task-project-ing-measure&category.type[]=task-project-ing-projecting&category.type[]=task-project-ing-meeting&category.type[]=task-project-ing-analyse&category.type[]=task-project-ing-supervision&category.type[]=task-project-ing-additional-request&category.type[]=task-project-ing-customer-service&category.type[]=task-project-ing-visit&valid=true";
            let urlParams = `${extraParams}&pagination=false&endAt[before]=${nextYear}` ;
            if (region !== "") {
                urlParams += `&region=${region}`
            }
            if (selectValue !== "" && selectValue !== userId.toString()) {
                urlParams += `&user.id=${selectValue}&category.type[]=task-day-off`
            }

            let request = await TaskService.getAllTasks("tasks" + urlParams )
            if (request.request.status === 200) {
                let allTasks = request.data['hydra:member'];
                allTasks = ConvertTasksToFullcalendarEvents(allTasks)
                setTasks(allTasks)
            } else {
                toast.error("Une erreur est survenue lors de la récupération des tâches")
            }
        };
        getTasks()
    }, [selectValue, region, showPopupAdd, showPopupEdit]);

    return (
        <section className="ing-prod-calendar-section">
            <div className="ing-prod-calendar-section__header">
                <h3 className={"color-primary"}>Planning</h3>
                <div>
                    <select
                        className={"select-custom-secondary"}
                        onChange={filterByCollaborator}
                    >
                        <option value={userId}>Mes tâches</option>
                        <option value="all">Tous les collaborateurs</option>
                        {collaborators.map((collaborator, index) => (
                            <option key={index} value={collaborator.id}>
                                {collaborator.niceNames}
                            </option>
                        ))}
                    </select>

                    <select
                        className={"select-custom-secondary"}
                        onChange={filterByRegion}
                    >
                        <option value={firstSelectDefaultValue}>{firstSelectDefaultValue}</option>
                        {firstSelectOptions.map((option, index) => (
                            <option key={index} value={firstSelectValue[index]}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <MbeCalendar
                tasks={tasks}
                taskManager={taskManager}
                taskCreatorFunction={DisplayCreateModalTask}
                taskEditorFunction={DisplayEditModalTask}
                withNotifications={false}
            />

            <PopUpForm
                large={true}
                show={showPopupAdd}
                onClick={DisplayCreateModalTask}
                title={"Ajouter une nouvelle tâche"}
                form={<FormAddAssignment setShowPopUp={setShowPopupAdd} newTask={newTask} role="INGS" />}
            />

            <PopUpForm
                large={true}
                show={showPopupEdit}
                title={showPopupEdit ? "Gestion de la tâche " + editTask.event.title : "Gestion de la tâche"}
                onClick={DisplayEditModalTask}
                form={<FormEditAssignment setShowPopUp={setShowPopupEdit} editTask={editTask} role="INGS" />}
            />
        </section>
    );
};

export default HomeIngManCalendar;

