import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

import TitleIconNavLink from "../../../../components/Atomes/Title/TitleIconNavLink";
import InputSearch from '../../../../components/Atomes/Inputs/InputSearch';
import Select from '../../../../components/Atomes/Select/Select';
import CardProjectLandscape from '../../../../components/Molecules/Cards/Project/CardProjectLandscape';

import { ArchService } from '../../../../services/ArchService';

const CustomerDetailArch = () => {

  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [customerInfos, setCustomerInfos] = useState({});
  const [customerInfosLoading, setCustomerInfosLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [projectFilterValue, setProjectFilterValue] = useState("Tous les projets");
  const projectFilterOptions = ["Projets en cours", "Projets terminés"];

  useEffect(() => {
    window.scrollTo(0, 0);
    getCustomerProjects();
  }, [search, projectFilterValue]);

  useEffect(() => {
    getCustomerInfos();
  }, [])

  const getCustomerInfos = async () => {
    let request = await ArchService.getCustomerInfos(id);
    if (request.status === 200) {
      setCustomerInfos(request.data);
      setCustomerInfosLoading(false);
    }
  }

  const getCustomerProjects = async () => {
    setProjectsLoading(true);
    let request = await ArchService.getCustomerProjects(id, { search: search });
    if (request.status === 200) {
      if (projectFilterValue === "Projets en cours") {
        request.data['hydra:member'] = request.data['hydra:member'].filter((project) => project.stage !== "COM_feedback");
      } else if (projectFilterValue === "Projets terminés") {
        request.data['hydra:member'] = request.data['hydra:member'].filter((project) => project.stage === "COM_feedback");
      } else {
        request.data['hydra:member'] = request.data['hydra:member'];
      }
      setProjects(request.data['hydra:member']);
      setProjectsLoading(false);
    }
  }

  const projectFilter = (value) => {
    setProjectFilterValue(value);
  }

  return (
    <section>
      {
        customerInfosLoading ? (
          <Skeleton height={40} width={200} />
        ) : (
          <TitleIconNavLink title={`Fiche client - ${customerInfos.firstname + ' ' + customerInfos.lastname}`} link={"/mes-clients"} />
        )
      }
      <section className={"customer-overview"}>

        <section className={"customer-overview__informations"}>
          {
            customerInfosLoading ? (
              <Skeleton height={450} width={200} />
            ) : (
              <>
                <img src={process.env.REACT_APP_PROD_URL + customerInfos.urlAvatar} />

                <div>
                  <p className={"text-primary color-dark-grey"}>Civilité</p>
                  <p className={"text-primary color-primary"}>{customerInfos.niceGender}</p>
                </div>

                <div>
                  <p className={"text-primary color-dark-grey"}>Nom client</p>
                  <p className={"text-primary color-primary"}>{customerInfos.firstname + ' ' + customerInfos.lastname}</p>
                </div>

                <div>
                  <p className={"text-primary color-dark-grey"}>Numéro client</p>
                  <p className={"text-primary color-primary"}>{customerInfos.reference}</p>
                </div>

                <div>
                  <p className={"text-primary color-dark-grey"}>Adresse e-mail</p>
                  <p className={"text-primary color-primary"}>{customerInfos.email}</p>
                </div>

                <div>
                  <p className={"text-primary color-dark-grey"}>Téléphone</p>
                  <p className={"text-primary color-primary"}>{customerInfos.phone}</p>
                </div>

                <div>
                  <p className={"text-primary color-dark-grey"}>Adresse de facturation</p>
                  <p className={"text-primary color-primary"}>{customerInfos.address?.street}, {customerInfos.address?.nicePostcode}, {customerInfos.address?.city}</p>
                </div>

                <div>
                  <p className={"text-primary color-dark-grey"}>Informations complémentaires</p>
                  <p className={"text-primary color-primary"}>N/A</p>
                </div>
              </>
            )
          }
        </section>


        <section className={"customer-overview__projects"}>
          <div>
            <InputSearch
              placeholder={"Rechercher un projet"}
              name={"SearchProject"}
              onChangeSearch={(event) => event.key === "Enter" ? setSearch(event.target.value.toLowerCase()) : null}
              onChangeSearchValue={(event) => event.target.value.length === 0 ? setSearch("") : null}
            />
            <Select
              styleSelect={"select-custom-secondary"}
              defaultValue={"Tous les projets"}
              value={projectFilterOptions}
              data={projectFilterOptions}
              onChange={(event) => projectFilter(event.target.value)}
            />
          </div>
          <div>
            {
              projectsLoading ? (
                <>
                  <Skeleton height={350} width="100%" />
                </>
              ) : (
                projects.length === 0 ? (
                  <div className='none'>
                    <p className={"text-primary color-primary"}>Aucun projet</p>
                  </div>
                ) : (
                  projects.map((project, index) => (
                    <CardProjectLandscape
                      project={project}
                      id={project.id}
                      key={index}
                    />
                  ))
                )
              )
            }
          </div>
        </section>
      </section>
    </section>
  )
};

export default CustomerDetailArch;
